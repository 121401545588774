<template>
    <div>
      <b-row class="mt-5">
        <b-col class="d-flex align-items-center justify-content-between">
          <span style="font-size:16px; font-weight:bold;">Cabang Terdaftar</span>
        </b-col>
      </b-row>
        <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                  <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                  <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                  <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                  <th style="min-width: 50px" class="text-capitalize">Status</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in listCabang">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ i + 1}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> Ormas test #{{i + 1}}
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">oT {{i+1}}</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">Tidak ada</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">Tidak ada</span>
                    </td>
                    <td class="text-left">
                      <span class="
                      text-dark-75
                      text-left
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">Tidak ada</span>
                    </td>                    
                    <td>
                      <section class="d-flex justify-content-center align-items-center">
                        <b-button
                          variant="danger"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-delete-outline</v-icon>
                        </b-button>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-pencil-outline</v-icon>
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="10"
                :per-page="2"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<script>
// import Swal from "sweetalert2";
export default {
  name: "cabang-terdaftar",
  data() {
    return {
      det: null,
      ormasIdentify : null,
      listCabang: [],
      tabDetailRiwayat: 0,
    };
  },
  mounted() {
    this.getListCabang()
  },
  watch:{
    activeTab(/*to*/){
      
    }
  },
  methods: {
    fetchData(){},
    getProfileInfo(){
      this.$store
        .dispatch("getProfileOrganization", null)
        .then((res) => {
          this.ormasIdentify = res.data
        })
    },
    getListCabang(){
      this.$store
        .dispatch("getListCabangLapor")
        // .dispatch("getStructureOrganization", 4)
        .then((res) => {
          this.listCabang = res.data.data
        })
    },
    detailRiwayat(/*item*/){
      this.getProfileInfo()
      this.getStructureOrganization()
      this.$store
      .dispatch("getDataPerubahan", 10)
      .then((res) => {
        this.det = res.data.data
        this.$bvModal.show('modal-detail-permohonan')
      })
    },
  },
  computed: {
    
  }
};
</script>